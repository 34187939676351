export const environment = {
  production: false,
  environmentName: 'qag5',
  adminSvcUrl: 'https://qa.paydatasync.com/adminservice/api/',
  broadcastingUrl: 'https://qa.paydatasync.com/AdminService',
  broadcastingUrl2: 'https://qa.paydatasync.com/SignalR',
  hubName: 'signalr',
  resourceSvcUrl: 'https://qa.paydatasync.com/resourceservice/api/',
  dwollaSvcUrl: 'https://qa.paydatasync.com/DwollaProxyAPI/api/',
  //crmProxyApiUrl: 'https://qaint.paydatasolutions.com/CRMPRoxyAPI/API/',
  broadcastingEnabled: true,
  broadcastingMaxReconnectCount: 9999,
  portalCode: 'PDP',
  loginIframeURL: 'https://info.paydayportal.com/',
  showFakeCard: false,
  oktaLoginURL:
    'https://thecheesecakefactory.okta.com/app/thecheesecakefactory_frcgratsync_1/exk10iu068zNSrLfV1t8/sso/saml',
};
